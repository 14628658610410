<script>
/**
 * @Author: Andrea Dittler | webspezi.com
 * @Date:   2023-04-03 19:58:45
 * @Last Modified by:   Andrea Dittler | webspezi.com
 * @Last Modified time: 2023-04-04 14:33:19
 */

</script>

<template>
  <div>
    <h1 class="special-heading light-special mb-5">Ihr Browser ist zu alt!</h1>
    <div role="" class="row justify-content-center align-items-start  wsFlexTyp-standard" id="Kombiblk_block1__6">
      <div class=" col-12 col-sm-6 col-lg-7 ">
      </div>
    </div>
    <div role="" class="row justify-content-center align-items-start  wsFlexTyp-standard">
      <div class="wsFlex col-12 col-sm-8 col-md-6 has-wsBlocksInfoBox KombiEbene1 ">

        <div class="milchglas position-relative" id="milchglas" ref="milchglas">
          <div class="innen" style="cursor: pointer;">
            <div class="iconbox iconbox-light iconbox-center zentriert">
              <div class="iconbox-wrapper">
                <div class="iconbox-icon"><i :class="`${os.icon} fa-6x fab`"></i></div>
                <div class="iconbox-content text-start">
                  <h2>Browser Weiche</h2>
                  <p>Ihr Browser ({{ os.browserName }} {{ os.browserVersion }}) erfüllt nicht alle
                    Vorraussetzungen.<br>
                    Die Webseite hat Sie zu dieser Seite verwiesen, da Ihr Browser leider nicht die Kriterien erfüllt, mit
                    denen diese Webseite genutzt werden kann. Wir möchten Sie daher bitten, einen der unten stehenden
                    Links zu nutzen und auf der jeweiligen Website einen aktuellen Browser kostenlos herunterzuladen.<br>
                  </p>
                  <h3>Gute Gründe für eine Browser-Aktualisierung:</h3>
                  <ul>
                    <li>sie nimmt nur wenige Minuten Zeit in Anspruch,</li>
                    <li>sie optimiert viele Darstellungen,</li>
                    <li>sie verkürzt Ladezeiten, und</li>
                    <li>sie dient Ihrer Sicherheit beim Surfen im Internet.</li>
                  </ul>
                  <table style="width: 100%;">
                    <tbody>
                      <tr>
                        <td align="center"><a href="https://www.google.com/chrome/" target="_blank"><img
                              src="../assets/chrome.png" alt="" width="48"></a></td>
                        <td align="center"><a href="https://www.mozilla.org/de/firefox/new/" target="_blank"><img
                              src="../assets/firefox.png" alt="" width="48"></a></td>
                        <td valign="middle" align="center"><a href="https://www.opera.com/de/download#opera-browser"
                            target="_blank"><img src="../assets/opera.png" alt="" width="48"></a>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center;"><a href="https://www.google.com/chrome/"
                            target="_blank">Chrome</a>
                        </td>
                        <td style="text-align: center;"><a href="https://www.mozilla.org/de/firefox/new/"
                            target="_blank">Firefox</a></td>
                        <td style="text-align: center;"><a href="https://www.opera.com/de/download#opera-browser"
                            target="_blank">Opera </a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount, computed, onMounted, onUpdated, watch } from 'vue'
import { applyMilchglasEffect } from './shared.js'
const os = computed(() => {
  const userAgent = window.navigator.userAgent;
  let os = null;

  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    os = { name: 'MacOS', icon: 'fa-apple', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Windows|Win16|Win32|Win64|WinCE/.test(userAgent)) {
    os = { name: 'Windows', icon: 'fa-windows', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Linux/.test(userAgent)) {
    os = { name: 'Linux', icon: 'fa-linux', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/iPhone|iPod|iPad/.test(userAgent)) {
    os = { name: 'iOS', icon: 'fa-apple', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Android/.test(userAgent)) {
    os = { name: 'Android', icon: 'fa-android', link: 'https://play.google.com/store/apps/details?id=com.teamviewer.quicksupport.market' };
  } else {
    os = { name: 'Unbekannt', icon: 'fa-question', link: 'https://get.teamviewer.com/webspezi' };
  }
  if (/MSIE|Trident/.test(userAgent)) {
    os.browserName = 'Internet Explorer';
    const match = userAgent.match(/(?:MSIE |rv:)(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else if (/Edge|Edg\//.test(userAgent)) {
    os.browserName = 'Microsoft Edge';
    const match = userAgent.match(/(?:Edge|Edg\/)(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else if (/Chrome/.test(userAgent)) {
    os.browserName = 'Google Chrome';
    const match = userAgent.match(/(?:Chrome|CriOS)\/(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else if (/Firefox/.test(userAgent)) {
    os.browserName = 'Mozilla Firefox';
    const match = userAgent.match(/(?:Firefox|Fxios)\/(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else if (/Safari/.test(userAgent)) {
    os.browserName = 'Safari';
    const match = userAgent.match(/(?:Safari|Version)\/(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else {
    os.browserName = 'Unbekannt';
    os.browserVersion = '';
  }
  return os;
})
onBeforeMount(() => {
  window.addEventListener('resize', applyMilchglasEffect);
  window.addEventListener('scroll', applyMilchglasEffect);
  //applyMilchglasEffect();
});
onUpdated(() => {
  applyMilchglasEffect();
});
onMounted(() => {
  applyMilchglasEffect();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', applyMilchglasEffect);
  window.removeEventListener('scroll', applyMilchglasEffect);
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div {
  font-size: clamp(16px, 1.3vw, 25px);
  font-weight: 400;
}
</style>
